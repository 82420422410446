import Vue from 'vue'

export default class FormDialogService {
  static _eventBus = new Vue()

  static subscribe (id, callBack) {
    return FormDialogService._eventBus.$on(id, callBack)
  }

  static unsubscribe (id) {
    return FormDialogService._eventBus.$off(id)
  }

  static on (id, title, message) {
    FormDialogService._eventBus.$emit(id, { isOn: true })
  }

  static off (id) {
    FormDialogService._eventBus.$emit(id, { isOn: false })
  }
}
