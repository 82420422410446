<template>
  <v-layout row justify-start fill-height>
    <v-flex v-if="items.length">
      <v-list two-line class="mt-0 fill-height">
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header">{{ item.header }}</v-subheader>

          <v-list-tile :key="item.title" avatar>
            <v-list-tile-avatar>
              <img :src="item.avatar" alt="Avatar">
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title v-html="item.title"></v-list-tile-title>
              <v-list-tile-sub-title class="text--primary">{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
    <v-layout v-else-if="empty" align-center justify-center row>
      <div class="subheading font-weight-light">{{ $t('tab.codigos.naoExistemCodigos') }}</div>
    </v-layout>
    <Progress id="Progress" />
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import ProgressService from '@/components/progress/ProgressService'
import Progress from '@/components/progress/Progress'

export default {
  name: 'CodeList',
  data: () => {
    return {
      items: [],
      valid: false,
      empty: false,
      active: null
    }
  },
  components: {
    Progress
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  mounted () {
    ProgressService.on('Progress')

    firebase.app().database().ref('codes')
      .orderByChild('uid')
      .equalTo(this.getUserData.uid)
      .once('value')
      .then(snap => {
        if (snap.val()) {
          let codes = Object.values(snap.val())
          codes.forEach(item => {
            let i = {
              avatar: require('@/assets/code.png'),
              title: item.id,
              subtitle: item.description
            }
            this.items.push(i)
          })
        } else {
          this.empty = true
        }
        ProgressService.off('Progress')
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>
