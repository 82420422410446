<template>
  <v-layout column>
    <v-tabs
      v-model="active"
      color="primary"
      dark
      slider-color="white"
    >
      <v-tab
        ripple
      >
        {{ $t('tab.codigos.meusCodigos') }}
      </v-tab>
    </v-tabs>

    <code-list :key="componentKey" />

    <v-fab-transition>
      <v-btn
        data-cy='add_code'
        dark
        fab
        fixed
        bottom
        right
        color='primary'
        class="mb-5"
        @click='add'
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
    <form-dialog id="addCodeFormDialog" @saveCode="saveCode">
      <v-layout justify-center fill-height class="pa-4">
        <v-flex>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-layout>
                <v-flex xs12>
                  <v-text-field
                    data-cy="id"
                    v-model="code.id"
                    :rules="fieldRules"
                    :label="$t('tab.codigos.form.codigo')"
                    mask="NNNNNNNNNN"
                    required
                  ></v-text-field>
                  <v-text-field
                    data-cy="description"
                    v-model="code.description"
                    :rules="fieldRules"
                    :label="$t('tab.codigos.form.descricao')"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </form-dialog>
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import util from '@/router/util'
import { mapGetters } from 'vuex'
import FormDialog from '@/components/dialogs/form/FormDialog'
import FormDialogService from '@/components/dialogs/form/FormDialogService'
import SnackbarService from '@/components/snackbar/SnackbarService'
import CodeList from '@/components/list/CodeList'

export default {
  name: 'Codes',
  data: () => {
    return {
      items: [],
      valid: false,
      empty: false,
      active: null,
      code: {},
      fieldRules: [],
      componentKey: 0
    }
  },
  components: {
    FormDialog,
    CodeList
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  mounted () {
    this.code.uid = this.getUserData.uid
    this.fieldRules = [v => !!v || this.$t('validacao.campoObrigatorio')]
  },
  methods: {
    add () {
      FormDialogService.on('addCodeFormDialog')
      // console.log(this.$refs.form.reset)
    },
    saveCode () {
      if (this.$refs.form.validate()) {
        firebase.database().ref('codes')
          .orderByChild('id')
          .equalTo(this.code.id)
          .once('value')
          .then(snap => {
            if (!snap.val()) {
              this.persistCode()
              this.reloadCodeList()
              this.clearClode()
              this.$refs.form.reset()
              FormDialogService.off('addCodeFormDialog')
            } else {
              SnackbarService.on('Snackbar', this.$t('tab.codigos.codigoExistente'))
            }
          })
          .catch(error => {
            console.log(error)
            this.$router.push(util.path.NOT_FOUND)
          })
      }
    },
    persistCode () {
      let ref = firebase.database().ref('codes')
      ref.push(this.code)
    },
    reloadCodeList () {
      this.componentKey += 1
    },
    clearClode () {
      this.code.id = null
      this.code.description = null
    }
  }
}
</script>
