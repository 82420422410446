<template>
  <div :id="id" class="text-xs-center">
    <v-dialog
      v-model="isOn"
      width="500"
      persistent
    >
      <v-card>
        <slot></slot>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            @click="isOn = false"
          >
            {{ $t('tab.codigos.cancelar') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="add"
          >
            {{ $t('tab.codigos.criar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FormDialogService from './FormDialogService'

export default {
  name: 'FormDialog',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOn: false
    }
  },
  created () {
    FormDialogService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
    })
  },
  destroyed () {
    FormDialogService.unsubscribe(this.id)
  },
  methods: {
    add () {
      this.$emit('saveCode')
    }
  }
}
</script>
